/* Background image. */

.medium {
    background-color: aliceblue;
}

body::after {
    content: "";
    background-image: url("../assets/images/background.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 0.15;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.h1-container {
    margin-top: 420px;
    margin-bottom: 60px;
}

@media (min-width: 768px) {
    .h1-container {
        margin-top: 180px;
    }
}

@media (min-width: 1440px) {
    .h1-container {
        margin-top: 330px;
    }
}

@media (min-width: 1920px) {
    .h1-container {
        /* margin-top: 380px; */
        margin-top: 320px;
        margin-bottom: 90px;
    }
}

.menu li:not(:last-child) {
    margin-bottom: 15px;
}

@media (min-width: 1920px) {
    .menu li:not(:last-child) {
        margin-bottom: 20px;
    }
}

.menu {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-bottom: 142px;
}

@media (min-width: 768px) {
    .menu {
        margin-bottom: 92px;
    }
}

@media (min-width: 1440px) {
    .menu {
        margin-bottom: 207px;
    }
}

@media (min-width: 1920px) {
    .menu {
        margin-bottom: 394px;
    }
}

.logo {
    position: absolute;
    object-fit: contain;
    top: 24px;
    right: 24px;
    width: 29px;
    height: 27px;
}

@media (min-width: 768px) {
    .logo {
        top: 60px;
        right: 64px;
        width: 42px;
        height: 39px;
    }
}

@media (min-width: 1440px) {
    .logo {
        width: 78px;
        height: 72px;
        right: 147px;
        top: 90px;
    }
}

@media (min-width: 1920px) {
    .logo {
        width: 96.42px;
        height: 89px;
        right: 150px;
        top: 90px;
    }
}

.subscribe-section__desc {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .subscribe-section__desc {
        margin-bottom: 30px;
    }
}

@media (min-width: 1440px) {
    .subscribe-section__desc {
        margin-bottom: 46px;
    }
}

@media (min-width: 1920px) {
    .subscribe-section__desc {
        margin-bottom: 60px;
    }
}

.section-wrapper {
    padding-left: 24px;
    padding-right: 24px;
}

@media (min-width: 768px) {
    .section-wrapper {
        padding-left: 64px;
        padding-right: 64px;
    }
}

@media (min-width: 992px) {
    .section-wrapper {
        padding-left: 150px;
        padding-right: 270px;
    }
}

.subscribe-section__suggest {
    margin-bottom: 7px;
}

@media (min-width: 768px) {
    .subscribe-section__suggest {}
}

@media (min-width: 1440px) {
    .subscribe-section__suggest {
        margin-bottom: 52px;
    }
}

@media (min-width: 1920px) {
    .subscribe-section__suggest {
        margin-bottom: 70px;
    }
}

.section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.read-more {
    font-family: Canela;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: 0.015em;
    margin-bottom: 60px;
}

.read-more,
.read-more * {
    font-size: 22px;
    line-height: 29px;
}

@media (min-width: 768px) {
    .read-more {
        margin-bottom: 125px;
    }
    .read-more,
    .read-more * {
        font-size: 24px;
        line-height: 31px;
    }
}

@media (min-width: 1440px) {
    .read-more {
        margin-bottom: 168px;
    }
    .read-more,
    .read-more * {
        font-size: 48px;
        line-height: 63px;
    }
}

@media (min-width: 1920px) {
    .read-more {
        margin-bottom: 236px;
    }
    .read-more,
    .read-more * {
        font-size: 64px;
        line-height: 83px;
    }
}

.article-for-users {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 45px;
}

.article-for-users__image {
    object-fit: cover;
    width: 100%;
}

.article-for-users__header {
    text-transform: uppercase;
    margin-bottom: 15px;
}

.article-for-users__content {
    width: 100%;
    margin-left: 24px;
}

.article-for-users__text {
    max-width: 80vw;
    /* 262px; */
}

.article-for-users__handwritten {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 0 0 auto;
    display: flex;
    margin-left: 24px;
    margin-top: 24px;
    margin-right: 24px;
    margin-bottom: 45px;
    flex-wrap: wrap;
    width: 100%;
}

@media (min-width: 768px) {
    .article-for-users {
        flex-wrap: nowrap;
        margin-bottom: 65px;
    }
    .article-for-users__image {
        order: 1;
        width: 320px;
        height: 200px;
        margin-right: 64px;
    }
    .article-for-users__header {
        margin-bottom: 15px;
    }
    .article-for-users__content {
        margin-left: 0px;
        order: 2;
        margin-top: 32px;
    }
    .article-for-users__text {
        max-width: 80%;
        /* max-width: calc(256px * 1.3); */
    }
    .article-for-users__handwritten {
        order: 3;
        margin-right: 32px;
        margin-bottom: 0px;
        width: auto;
    }
}

@media (min-width: 1440px) {
    .article-for-users {
        margin-bottom: 88px;
    }
    .article-for-users__image {
        width: 630px;
        height: 420px;
        margin-right: 120px;
    }
    .article-for-users__header {
        margin-bottom: 26px;
    }
    .article-for-users__content {
        margin-top: 100px;
    }
    .article-for-users__text {
        max-width: 80%;
        /* max-width: calc(300px * 1.3); */
    }
    .article-for-users__handwritten {
        margin-right: 150px;
    }
}

@media (min-width: 1920px) {
    .article-for-users {
        margin-bottom: 96px;
    }
    .article-for-users__image {
        width: 870px;
        height: 580px;
        margin-right: 180px;
    }
    .article-for-users__header {
        margin-bottom: 35px;
    }
    .article-for-users__content {
        margin-top: 120px;
    }
    .article-for-users__text {
        max-width: 80%;
        /* calc(390px * 1.3); */
    }
    .article-for-users__handwritten {
        margin-right: 165px;
    }
}


/* for partners */

.article-for-partners {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 45px;
}

.article-for-partners__body {
    flex: 1;
}

.article-for-partners__image {
    object-fit: cover;
    width: 100%;
}

.article-for-partners__header {
    text-transform: uppercase;
    margin-bottom: 15px;
}

.article-for-partners__content {
    width: 100%;
    margin-left: 24px;
    margin-bottom: 30px;
    margin-top: 0px;
}

.article-for-partners__text {
    /* max-width: 295px; */
    max-width: 85vw;
}

.article-for-partners__handwritten {
    flex: 1;
    display: flex;
    align-items: flex-end;
    flex: 0 0 auto;
    display: flex;
    margin-left: 24px;
    margin-bottom: 32px;
}

@media (min-width: 768px) {
    .article-for-partners {
        flex-wrap: nowrap;
        margin-bottom: 45px;
    }
    .article-for-partners__body {
        margin-left: 64px;
    }
    .article-for-partners__image {
        order: 3;
        width: 320px;
        height: 400px;
    }
    .article-for-partners__header {
        margin-bottom: 15px;
    }
    .article-for-partners__content {
        margin-left: 0px;
        order: 1;
        margin-bottom: 51px;
        margin-top: 80px;
    }
    .article-for-partners__text {
        /* max-width: 320px; */
        max-width: 90%;
    }
    .article-for-partners__handwritten {
        order: 2;
        margin-left: 0px;
    }
}

@media (min-width: 1440px) {
    .article-for-partners {
        margin-bottom: 94px;
    }
    .article-for-partners__body {
        margin-left: 150px;
    }
    .article-for-partners__image {
        width: 570px;
        height: 740px;
    }
    .article-for-partners__header {
        margin-bottom: 26px;
    }
    .article-for-partners__content {
        margin-left: 60px;
        margin-top: 160px;
        margin-bottom: 118px;
    }
    .article-for-partners__text {
        max-width: 85%;
        /* max-width: 540px; */
    }
    .article-for-partners__handwritten {}
}

@media (min-width: 1920px) {
    .article-for-partners {
        margin-bottom: 165px;
    }
    .article-for-partners__image {
        width: 690px;
        height: 900px;
    }
    .article-for-partners__header {
        margin-bottom: 35px;
    }
    .article-for-partners__content {
        /* margin-left: 180px; */
        margin-left: 140px;
        margin-top: 240px;
        margin-bottom: 169px;
    }
    .article-for-partners__text {
        max-width: 80%;
        /* max-width: 720px; */
    }
    .article-for-partners__handwritten {}
}


/* preregister */

.preregister-form {
    display: flex;
    flex: 1 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 90px;
}

@media (min-width: 768px) {
    .preregister-form {
        margin-bottom: 65px;
    }
}

@media (min-width: 1440px) {
    .preregister-form {
        margin-bottom: 125px;
    }
}

@media (min-width: 1920px) {
    .preregister-form {
        margin-bottom: 200px;
    }
}

.preregister-form-input-container {
    display: flex;
    flex: 1 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.preregister-form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.preregister-form>div {}

.preregister-form__field {
    flex-direction: row;
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 100%;
    margin-bottom: 30px;
    padding: 0;
    margin-right: 0.5em;
}

@media (min-width: 768px) {
    .preregister-form__field {
        min-width: auto;
    }
}

.preregister-form__input {
    flex: 1;
    background-color: transparent;
    border: 0;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    letter-spacing: -0.2px;
    text-transform: capitalize;
}

.preregister-form__actions {
    flex: 0;
    min-width: 100%;
}

@media (min-width: 768px) {
    .preregister-form__actions {
        min-width: auto;
    }
}

.preregister-form__submit {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.preregister-form__submit::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00114 7.4742L7.71426 4.45297H0V3.02123H7.71426L5.00114 0H7.25165L10.6774 3.7371L7.25165 7.4742H5.00114Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    width: 1em;
    height: 1em;
    display: inline-flex;
    margin-left: 1em;
}

.button:hover .preregister-form__submit::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00114 7.4742L7.71426 4.45297H0V3.02123H7.71426L5.00114 0H7.25165L10.6774 3.7371L7.25165 7.4742H5.00114Z' fill='black'/%3E%3C/svg%3E%0A");
}


/* Hover layout for menu link hover effect */


/* 
.hover-reveal {
	position: absolute;
	z-index: -1;
	width: 300px;
	height: 400px;
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0;
	will-change: transform, filter;
}

.hover-reveal__inner {
	overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
	width: 100%;
	height: 100%;
	position: relative;
}

.hover-reveal__img {
	background-size: cover;
	background-position: 50% 50%;
} */


/* Top right image set ribbon */

.image-set {
    position: absolute;
    top: 0;
    right: 0;
    width: 120vw;
    object-fit: contain;
}

@media (min-width: 768px) {
    .image-set {
        width: 80vw;
    }
}

@media (min-width: 1024px) {
    .image-set {
        width: 70vw;
    }
}

@media (min-width: 1440px) {
    .image-set {
        width: 80vw;
    }
}

@media (min-width: 1920px) {
    .image-set {
        width: 80vw;
    }
}


/* Put by js after submit form */

.beta-form__field--validated:invalid {
    /* background-color: rgba(255, 64, 64, 0.1); */
    border-color: rgba(255, 64, 64, 1) !important;
}


/* Social links */

.social-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 70px;
    flex: 1 0;
    height: 52px;
}

@media (min-width: 768px) {
    .social-panel {
        justify-content: flex-start;
        margin-bottom: 65px;
        height: 56px;
    }
}

@media (min-width: 1440px) {
    .social-panel {
        margin-bottom: 125px;
        height: 100px;
    }
}

@media (min-width: 1920px) {
    .social-panel {
        margin-bottom: 200px;
        height: 123px;
    }
}

.social-panel__link:not(:last-child) {
    margin-right: 2rem;
}

.social-panel__link {}

.social-panel__image {
    object-fit: contain;
    width: 145px;
    height: 52px;
}

@media (min-width: 768px) {
    .social-panel__image {
        width: 150px;
        height: 56px;
    }
}

@media (min-width: 1440px) {
    .social-panel__image {
        width: 280px;
        height: 100px;
    }
}

@media (min-width: 1920px) {
    .social-panel__image {
        width: 339px;
        height: 123px;
    }
}